/**
  * Sliders
 */

 const slider = () => {
  const mv = document.querySelector('.slider-mv')

  if(mv) {
    new Flickity(mv, {
      wrapAround: true,
      autoPlay: 5000,
      contain: true,
      fade: true,
      prevNextButtons: false,
      pauseAutoPlayOnHover: true
    })
  }

  const topinfo = document.querySelector('.slider-topinfo')

  if(topinfo) {
    new Flickity(topinfo, {
      watchCSS: true,
      wrapAround: true,
      cellAlign: 'center',
      contain: false,
      wrapAround: true,
      fade: true,
      // adaptiveHeight: true
    })
  }
}

export default slider