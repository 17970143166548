import sampleJs from './components/sampleJs'
import commonGuide from './components/commonGuide'
import flickityInit from './components/flickityInit'
import ajaxzip from './components/ajaxzip'
import productGallery from './components/productGallery'
import cartQuantity from './components/cartQuantity'
import prodQuantity from './components/prodQuantity'
import cartDelivery from './components/cartDelivery'
import sitemapToggle from './components/sitemapToggle'
import welcartzip from './components/welcartzip'
import btnvalChanger from './components/btnvalChanger'
import slider from './components/slider'
import foreachPolyfill from './components/foreachPolyfill'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    foreachPolyfill()
    sampleJs()
    commonGuide()
    slider()
    flickityInit()
    ajaxzip()
    productGallery()
    cartQuantity()
    cartDelivery()
    sitemapToggle()
    welcartzip()
    prodQuantity()
    btnvalChanger()
  },
  false
);
