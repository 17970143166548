/**
  * Toggler for common guide items SP
 */

 const commonGuide = () => {
  if(screen.width < 768) {
    let items = document.querySelectorAll('.common-guide__shopping-item--toggler');

    for(let i = 0; i < items.length; i++) {
      items[i].querySelector('.common-guide__shopping-item-title').addEventListener('click', function(){
        items[i].classList.toggle('common-guide__shopping-item--toggler-active');
      });
    }
  }
}

export default commonGuide