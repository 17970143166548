/**
  * Input button value changer
 */

 const btnvalChanger = () => {
  try {
    let btn = document.querySelector('input[name="regmember"]');
    btn.value = "登録する";
  } catch (error) {
    console.log(error)
  }

}

export default btnvalChanger