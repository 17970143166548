/**
  * Quantity changer for product desc
 */

 const prodQuantity = () => {
  try {
    let item = document.querySelectorAll('.product-desc__quantity');

    for(let i = 0; i < item.length; i++) {
      item[i].querySelector('.product-desc__quantity-changer--increment').addEventListener('click', function(){
        item[i].querySelector('input').value = (item[i].querySelector('input').value * 1) + 1;
      });
  
      item[i].querySelector('.product-desc__quantity-changer--decrement').addEventListener('click', function() {
        if(item[i].querySelector('input').value > 0)
          item[i].querySelector('input').value = (item[i].querySelector('input').value * 1) - 1;
      }); 
    }

  } catch (error) {
   console.log(error); 
  }
}

export default prodQuantity