/**
  * Toggler for product gallery images
 */

 const productGallery = () => {
  const lists = document.querySelectorAll('.product-desc__gallery-list')
  const mains = document.querySelectorAll('.product-desc__gallery-main')

  if(lists) {
    function clear() {
      lists.forEach(list => {
        const items = list.querySelectorAll('.product-desc__gallery-item')

        items.forEach(item => {
          item.classList.remove('product-desc__gallery-item--active')
        })
      })
    }

    for(let i = 0; i < lists.length; i++) {
      const items = lists[i].querySelectorAll('.product-desc__gallery-item')

      for(let j = 0; j < items.length; j++) {
        items[j].addEventListener('click', function() {
          mains.forEach(main => {
            main.querySelector('img').src = items[j].querySelector('img').src;
          })

          clear()
          for(let k = 0; k < lists.length; k++) {
            const items2 = lists[k].querySelectorAll('.product-desc__gallery-item')
            items2[j].classList.add('product-desc__gallery-item--active');
          }
        })
      }
    }
  }

  // try {
  //   let items = document.querySelectorAll('.product-desc__gallery-item');
  //   let mains = document.querySelectorAll('.product-desc__gallery-main');

  //   for(let i = 0; i < items.length; i++ ) {
  //     items[i].addEventListener('click', function(){
  //       for(let a = 0; a < items.length; a++ ) {
  //         items[a].classList.remove('product-desc__gallery-item--active');
  //       }

  //       items[i].classList.add('product-desc__gallery-item--active');
  //       mains.forEach(main => {
  //         main.querySelector('img').src = items[i].querySelector('img').src;
  //       })
  //     });
  //   }
  // } catch (error) {
  //   console.log(error)
  // }
}

export default productGallery