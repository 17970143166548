/**
  * welcart zip auto-fill
 */

 const welcartzip = () => {
  var button = document.querySelector(".button__simple--zipcode");

  if(button) {
    
    if(button.classList.contains('zipcode-register')) {
      var fulladdress = '';
      button.addEventListener('click', function() {
        AjaxZip3.zip2addr('member[zipcode]','','member[pref]','member[address1]','member[address2]');
        setTimeout(function() {
          document.querySelector('input[name=\'fulladdress\']').value = document.querySelector('select[name=\'member[pref]\']').value + document.querySelector('input[name=\'member[address1]\']').value + document.querySelector('input[name=\'member[address2]\']').value;
          fulladdress =  document.querySelector('select[name=\'member[pref]\']').value + document.querySelector('input[name=\'member[address1]\']').value + document.querySelector('input[name=\'member[address2]\']').value;
          document.querySelector('input[name=\'fulladdress\']').disabled = false;
        }, 500);
      });

      document.querySelector('input[name=\'fulladdress\']').addEventListener('change', function(){
        document.querySelector('input[name=\'member[address3]\']').value = document.querySelector('input[name=\'fulladdress\']').value.substring(fulladdress.length);
      })
    }
    else if(button.classList.contains('zipcode-delivery')) {
      var fulladdress = '';
      button.addEventListener('click', function() {
        AjaxZip3.zip2addr('delivery[zipcode]','','delivery[pref]','delivery[address1]','delivery[address2]');
        setTimeout(function() {
          document.querySelector('input[name=\'fulladdress\']').value = document.querySelector('select[name=\'delivery[pref]\']').value + document.querySelector('input[name=\'delivery[address1]\']').value + document.querySelector('input[name=\'delivery[address2]\']').value;
          fulladdress =  document.querySelector('select[name=\'delivery[pref]\']').value + document.querySelector('input[name=\'delivery[address1]\']').value + document.querySelector('input[name=\'delivery[address2]\']').value;
          document.querySelector('input[name=\'fulladdress\']').disabled = false;
        }, 500);
      });
  
      document.querySelector('input[name=\'fulladdress\']').addEventListener('change', function(){
        document.querySelector('input[name=\'customer[address3]\']').value = document.querySelector('input[name=\'fulladdress\']').value.substring(fulladdress.length);
      })
    }
    else if(button.classList.contains('zipcode-msa')) {
      var fulladdress = '';
      button.addEventListener('click', function() {
        AjaxZip3.zip2addr('msa_zip','','msa_pref','msa_address1','msa_address2');
        setTimeout(function() {
          document.querySelector('input[name=\'fulladdress\']').value = document.querySelector('select[name=msa_pref]').value + document.querySelector('input[name=msa_address1]').value + document.querySelector('input[name=msa_address2]').value;
          fulladdress =  document.querySelector('select[name=msa_pref]').value + document.querySelector('input[name=msa_address1]').value + document.querySelector('input[name=msa_address2]').value;
          document.querySelector('input[name=\'fulladdress\']').disabled = false;
        }, 500);
      });

      document.querySelector('input[name=\'fulladdress\']').addEventListener('change', function(){
        document.querySelector('input[name=msa_address3]').value = document.querySelector('input[name=\'fulladdress\']').value.substring(fulladdress.length);
      })
    }
    else {
      var fulladdress = '';
      button.addEventListener('click', function() {
        AjaxZip3.zip2addr('customer[zipcode]','','customer[pref]','customer[address1]','customer[address2]');
        setTimeout(function() {
          document.querySelector('input[name=\'fulladdress\']').value = document.querySelector('select[name=\'customer[pref]\']').value + document.querySelector('input[name=\'customer[address1]\']').value + document.querySelector('input[name=\'customer[address2]\']').value;
          fulladdress =  document.querySelector('select[name=\'customer[pref]\']').value + document.querySelector('input[name=\'customer[address1]\']').value + document.querySelector('input[name=\'customer[address2]\']').value;
          document.querySelector('input[name=\'fulladdress\']').disabled = false;
        }, 500);
      });
  
      document.querySelector('input[name=\'fulladdress\']').addEventListener('change', function(){
        document.querySelector('input[name=\'customer[address3]\']').value = document.querySelector('input[name=\'fulladdress\']').value.substring(fulladdress.length);
      })
    }
  }
}

export default welcartzip