/**
  * functions for cart 3
 */

 const cartDelivery = () => {
  try {
    /*let items = document.querySelectorAll('.delviery_address');
    let address_fields = document.querySelector('.cart__row-diffaddress');

    for(let i = 0; i < items.length; i++) {
      if(items[i].querySelector('input').value == '別の住所（1ヵ所）に送る' && items[i].querySelector('input').checked == true) {
        address_fields.style.display = 'block';
        break;
      }
      else
        address_fields.style.display = 'none';
    }

    for(let i = 0; i < items.length; i++) {
      items[i].addEventListener('click', function(){
        if(items[i].querySelector('input').value == '別の住所（1ヵ所）に送る' && items[i].querySelector('input').checked == true) {
          address_fields.style.display = 'block';
        }
        else
          address_fields.style.display = 'none';
      })
    }*/
  } catch (error) {
   //console.log(error); 
  }

  //Credit
  try {
    /*let items = document.querySelectorAll('.payment');
    let address_fields = document.querySelector('.cart__creditcard');

    for(let i = 0; i < items.length; i++) {
      if(items[i].querySelector('input').value == 'クレジットカード決済' && items[i].querySelector('input').checked == true) {
        address_fields.style.display = 'block';
        break;
      }
      else
        address_fields.style.display = 'none';
    }

    for(let i = 0; i < items.length; i++) {
      items[i].addEventListener('click', function(){
        if(items[i].querySelector('input').value == 'クレジットカード決済' && items[i].querySelector('input').checked == true) {
          address_fields.style.display = 'block';
        }
        else
          address_fields.style.display = 'none';
      })
    }*/
  } catch (error) {
   //console.log(error); 
  }
}

export default cartDelivery