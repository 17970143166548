/**
  * Toggler for sitemap accordion
 */

 const sitemapToggle = () => {
  const items = document.querySelectorAll('.sitemap__item.has-sub') || null;

  if (items) {
    [].forEach.call(items, function(link) {
      link.addEventListener('click', function(e) {
        e.preventDefault();
        
        this.classList.toggle('toggled');
      });
    });
  }
}

export default sitemapToggle