/**
  * Sample JS
 */

const sampleJS = () => {
  let ham = document.querySelector('.nav__commerce-link--hamburger');

  ham.addEventListener('click', function(){
    let nav = document.querySelector('.nav__lower')
    ham.classList.toggle('nav__commerce-link--hamburger-close');
    nav.classList.toggle('nav__lower--active')
  });
}

export default sampleJS