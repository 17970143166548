/**
  * Toggler for common guide items SP
 */

 const flickityInit = () => {
  try {
    if(screen.width < 768) {
      var elem = document.querySelector('.top__information-list');
      var flkty = new Flickity( elem, {
        // options
        cellAlign: 'center',
        contain: false,
        wrapAround: true,
      });
    }
  } catch (error) {
    console.log(error);
  }

  try {
    var elem = document.querySelector('.product__banner-wrapper');
    var flkty = new Flickity( elem, {
      // options
      cellAlign: 'center',
      contain: false,
      wrapAround: true,
    });
  } catch (error) {
    
  }
}

export default flickityInit